@font-face {
  font-family: standardFont;
  src: url('../../assets/fonts/vialto/HelveticaNeueLTW01-55Roman.woff');
  font-weight: 400;
  font-style: normal;
  color: #2d2d2d;
}

@font-face {
  font-family: boldFont;
  src: url('../../assets/fonts/vialto/HelveticaNeueLTW01-65Medium.woff');
  font-weight: 500;
  font-style: normal;
  color: '#000';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mw {
  max-width: 100%;
}

.plr-20 {
  padding: 20px 20px;
}

.layer {
  background-color: rgba(45, 45, 45, 0.6);
  padding-top: 170px;
  width: 100%;
  height: 100%;
}

.screen-container {
  font-family: standardFont;
}

.header-text {
  display: flex;
  background-color: #2d2d2d;
  justify-content: flex-start;
  padding-left: 10px;
  color: white;
  font-family: boldFont;
}

.header-text_title {
  font-size: 24px;
}

.header-text_description {
  font-size: 18px;
}

.header-description {
  display: flex;
  background-color: #2d2d2d;
  justify-content: flex-start;
  font-size: 18px;
  padding-left: 10px;
  color: white;
  font-family: boldFont;
}

.header-info {
  display: 'flex';
  justify-content: 'center';
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: boldFont;
  font-size: 16px;
  color: black;
}

.form-container {
  background-color: white;
  border: 2px solid #dedede;
  margin: 0 auto;
  margin-bottom: 25px;
  width: 50%;
}

.submitted-form {
  text-align: center;
  font-family: boldFont;
  font-size: 24px;
  color: black;
  margin: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.s-container {
  width: 100%;
  height: 80px;
  margin-bottom: 200px;
  border-bottom: hsl(246, 100%, 47%) 5px solid;
}

.category {
  margin-bottom: 25px;
  margin-top: 10px;
  color: black;
  font-size: 14px;
  font-weight: 700;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.action-button {
  font-size: 12px;
  width: 150px;
  margin: 10px;
  border-radius: 5px;
}

.footer {
  display: flex;
  justify-content: 'flex-start';
  height: 40px;
  color: #f7f7f7;
  padding: 5px;
  font-size: 12px;
  text-align: 'left';
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #2d2d2d;
}

.footer-copyrights {
  margin-left: 15px;
  margin-top: 5px;
  color: lightgray;
}

.footer-container {
  width: 100%;
  height: 30px;
  margin-top: 60px;
  border-top: #4000ef 5px solid;
  text-align: center;
  padding-top: 10px;
}
.header-menu-option:hover {
  cursor: pointer;
}

.header-menu-option {
  margin-top: 20px;
  font-weight: 600;
}
.sform {
  padding-left: 20px;
}

.vialto {
  box-sizing: border-box;
  background: no-repeat;
  height: 25px;
  width: 40px;
  background-image: url(../../assets/img/vialto-logo-outline-white.svg);
}

.trips {
  box-sizing: border-box;
  background: no-repeat;
  height: 25px;
  width: 50px;
  background-image: url(../../assets/img/icon-vialto.svg);
}

.ant-form-item.ant-form-item-view-mode {
  margin-bottom: 20px;
}
.ant-form-item.ant-form-item-view-mode-v4 {
  margin-bottom: 5px;
}

.ant-form-item-view-mode .ant-form-item-label {
  text-align: left;
  font-weight: 400;
  font-size: '10px';
  line-height: 1.5;
}

.ant-form-item-view-mode .ant-form-item-control {
  line-height: 1.5;
}

.ant-form-item-children .antd-form-builder-string-content {
  line-height: 1.5;
  display: inline-block;
  padding-top: 9px;
}

.ant-form-item-view-mode .antd-form-builder-string-content {
  display: inline;
  padding-top: 0;
}

.antd-form-builder-question-icon {
  fill: #40a9ff;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.ant-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f0f0 !important;
  color: black;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.ant-input:hover {
  border: 1px solid #f5f0f0;
}

.ant-input:selected {
  border: 1px solid #f5f0f0;
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-family: standardFont;
  font-size: 12px !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 2px;
  color: #db536a;
  font-weight: bold;
  font-size: 10px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-picker {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f0f0 !important;
  color: black;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  width: 100%;
}
